.inputAreaWrapper {
  line-height: 24px;
  font-size: 16px;
  color: rgba(51, 51, 51, 0.7);
  font-weight: 400;
  width: 100%;
  /* overflow-y: auto; */
  margin-bottom: 36px;
  padding-top: 24px;
}
.inputAreaWrapper:focus-visible {
  outline: none;
}
.separator{
  
  border-bottom: 1px solid #d0d8e2;

}
.inputAreaWrapper > a{
  color: #007AFF;
  text-decoration: none;
}