@import '../abstracts/functions';

// REM mixin wrappers

@mixin scroll-bar() {
    /* width */
    ::-webkit-scrollbar {
        width: 6px;
        display: block;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f5f5f5;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #999;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #666;
    }
}

@mixin margin($args) {
    margin: $args;
    margin: convert-to-rem($args);
}

@mixin padding($args) {
    padding: $args;
    padding: convert-to-rem($args);
}

@mixin margin-left($args) {
    margin-left: $args;
    margin-left: convert-to-rem($args);
}

@mixin margin-right($args) {
    margin-right: $args;
    margin-right: convert-to-rem($args);
}

@mixin margin-top($args) {
    margin-top: $args;
    margin-top: convert-to-rem($args);
}

@mixin margin-bottom($args) {
    margin-bottom: $args;
    margin-bottom: convert-to-rem($args);
}
@mixin margin-top-bottom($args) {
    margin: $args 0;
    margin: convert-to-rem($args) 0;
}

@mixin margin-left-right($args) {
    margin: 0 $args;
    margin: 0 convert-to-rem($args);
}

@mixin padding-left-right($args) {
    padding: 0 $args;
    padding: 0 convert-to-rem($args);
}

@mixin padding-top-bottom($args) {
    padding: $args 0;
    padding: convert-to-rem($args) 0;
}

@mixin padding-all($args1, $args2) {
    padding: $args1 $args2;
    padding: convert-to-rem($args1 $args2);
}

@mixin padding-left($args) {
    padding-left: $args;
    padding-left: convert-to-rem($args);
}

@mixin padding-right($args) {
    padding-right: $args;
    padding-right: convert-to-rem($args);
}

@mixin padding-top($args) {
    padding-top: $args;
    padding-top: convert-to-rem($args);
}

@mixin padding-bottom($args) {
    padding-bottom: $args;
    padding-bottom: convert-to-rem($args);
}

@mixin font-size($args) {
    font-size: $args;
    font-size: convert-to-rem($args);
}

@mixin width($args) {
    width: $args;
    width: convert-to-rem($args);
}

@mixin height($args) {
    height: $args;
    height: convert-to-rem($args);
}

@mixin min-width($args) {
    min-width: $args;
    min-width: convert-to-rem($args);
}

@mixin min-height($args) {
    min-height: $args;
    min-height: convert-to-rem($args);
}

@mixin max-width($args) {
    max-width: $args;
    max-width: convert-to-rem($args);
}

@mixin max-height($args) {
    max-height: $args;
    max-height: convert-to-rem($args);
}

@mixin line-height($args) {
    line-height: $args;
    line-height: convert-to-rem($args);
}

@mixin letter-spacing($args) {
    letter-spacing: $args;
    letter-spacing: convert-to-rem($args);
}

@mixin top($args) {
    top: $args;
    top: convert-to-rem($args);
}

@mixin left($args) {
    left: $args;
    left: convert-to-rem($args);
}

@mixin bottom($args) {
    bottom: $args;
    bottom: convert-to-rem($args);
}

@mixin right($args) {
    right: $args;
    right: convert-to-rem($args);
}

@mixin margin($args) {
    margin: $args;
    margin: convert-to-rem($args);
}

// Darken and Lighten text color
@mixin tran($type, $color, $percentage) {
    @if $type == color {
        color: trans($color, $percentage);
    }
    @if $type == bg {
        background-color: trans($color, $percentage);
    }
}

// To make triangles like dropdown
@mixin triangle($direction, $size: 0.375rem, $color: $dark) {
    content: '';
    display: block;
    position: absolute;
    height: 0;
    width: 0;
    @if ($direction == 'up') {
        border-bottom: $size solid $color;
        border-left: $size solid transparent;
        border-right: $size solid transparent;
    } @else if ($direction == 'down') {
        border-top: $size solid $color;
        border-left: $size solid transparent;
        border-right: $size solid transparent;
    } @else if ($direction == 'left') {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
    } @else if ($direction == 'right') {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
    }
}

// Absolute Centering
@mixin absolutecenter($axis: 'both') {
    position: absolute;
    @if $axis == 'y' {
        top: 50%;
        transform: translateY(-50%);
    }
    @if $axis == 'x' {
        left: 50%;
        transform: translateX(-50%);
    }
    @if $axis == 'both' {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

// Transition, animation and transform mixins
@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin rotate($deg) {
    -webkit-transform: rotate($deg);
    -moz-transform: rotate($deg);
    -ms-transform: rotate($deg);
    -o-transform: rotate($deg);
    transform: rotate($deg);
}

@mixin transform-translate($x, $y) {
    -ms-transform: translate($x, $y);
    -webkit-transform: translate($x, $y);
    -moz-transform: translate($x, $y);
    -o-transform: translate($x, $y);
    transform: translate($x, $y);
}

@mixin transform-origin($args...) {
    -webkit-transform-origin: $args;
    -moz-transform-origin: $args;
    -ms-transform-origin: $args;
    -o-transform-origin: $args;
    transform-origin: $args;
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($args...) {
    -webkit-animation: #{$args};
    -moz-animation: #{$args};
    -ms-animation: #{$args};
    -o-animation: #{$args};
    animation: #{$args};
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -webkit-border-radius: convert-to-rem($radius);
    -moz-border-radius: $radius;
    -moz-border-radius: convert-to-rem($radius);
    border-radius: $radius;
    border-radius: convert-to-rem($radius);
}

@mixin border-width($args) {
    border-width: $args;
    border-width: convert-to-rem($args);
}

@mixin full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin absolute-center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

// mixin shadow
@mixin box-shadow($args...) {
    -webkit-box-shadow: $args;
    -moz-box-shadow: $args;
    box-shadow: $args;
}

// mixin ellipsis
@mixin text-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// mixin for multiline ellipsis === -webkit-* only
@mixin multiline-ellipsis($line-height, $lines) {
    display: block;
    display: -webkit-box;
    max-height: $line-height * $lines;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
}

// mixin circle
@mixin circle($diameter) {
    @include width($diameter);
    @include height($diameter);
    border-radius: 50%;
}

// Vertical scroll touch
@mixin vertical-scroll-touch {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

// Horizontal scroll touch
@mixin horizontal-scroll-touch {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

// mixin for display flex
@mixin display-flex {
    display: table;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin column-count($args) {
    -moz-column-count: $args;
    -webkit-column-count: $args;
    column-count: $args;
}

@mixin column-gap($args) {
    column-gap: $args;
    column-gap: convert-to-rem($args);
}

@mixin animation-delay($sec) {
    -webkit-animation-delay: $sec;
    animation-delay: $sec;
}

@mixin user-select($args) {
    -webkit-user-select: $args;
    -moz-user-select: $args;
    -ms-user-select: $args;
    user-select: $args;
}

// mixin for position sticky
@mixin position-sticky {
    position: -webkit-sticky;
    position: sticky;
}
