.commentInputBox {
  display: flex;
  padding: 12px 0px;
  align-items: center;
}
.inputDiv {
  flex-grow: 1;
  position: relative;
  margin-left: 8px;
}
.lmProfile {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#editableDiv {
  resize: none;
  font-weight: 400;
  font-size: 14px;
  overflow-y: auto;
  padding: 8px 0px;
  color: rgba(72, 79, 103, 0.7);
  /* border: 1px solid #D0D8E2; */
  /* border-radius: 24px; */
  line-height: 20px;
  /* height: 40px; */
  /* display: flex; */
  align-items: center;
  max-height: 60px;
}
#editableDiv:focus {
  outline: none;
}
.postCommentButton {
}
.commentsWrapper {
  /* max-height: 400px;
  overflow-y: scroll; */
  padding: 0 0 16px 0;
  /* height: 100%; */
}
.commentCountDiv {
  padding: 16px 16px 8px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #484f67;
}
.lmOverflowMenuTitle:hover {
  background-color: rgba(230, 235, 245, 1);
}
.lmWrapper__feed__post__footer {
  padding: 10px 0px 10px 0px;
}
