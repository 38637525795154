.resourceHeaderWrapper {
  height: 64px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: white;
  flex-grow: 1;
}

.resourceHeaderWrapper > span {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.newResourceCreationButton {
  background-color: #00897b;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 8px;
  width: 139px;
  height: 36px;
  text-align: left;
  display: flex;
  align-items: center;
  font-family: Roboto;
  cursor: pointer;
}
.newResourceCreationButton > span {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
  margin-left: 6px;
}
.resourceMenuTile {
  height: 48px;
  width: 200px;
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.resourceMenuTile > span {
  font-size: 16px;
  line-height: 20px;
  margin-left: 19px;
  font-weight: 400;
  font: Roboto;
}
.resourceMenuTile:hover {
  background-color: #e6ebf5;
}
