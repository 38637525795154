.ResourceViewWrapper {
  width: 576px;
  background-color: white;
  margin-bottom: 14px;
  margin: 0px auto;
}
.ResourceViewWrapper--details{
  padding-top: 0px;
  width: 576px;
  background-color: white;
  margin-bottom: 14px;
  margin: 0px auto;
  border-radius: 8px;
}