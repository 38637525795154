.block {
  /* margin-bottom: 24px; */
}
.uploadBlockWrapper {
  min-height: 164px;
  background-color: rgba(208, 216, 226, 0.4);
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}
.svgBlock {
  text-align: center;
  margin-bottom: 4px;
}
.blockText {
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
}

label {
  cursor: pointer;
  display: inline !important;
}
.imageWrapperContainer {
  width: 100%;
  height: 164px;
  display: flex;
  justify-content: center;
  background-color: black;
  position: relative;
  margin-top: 24px;
  border-radius: 8px;
}
.imageBlockWrapper {
  max-height: 270px;
  max-width: 480px;
  overflow: hidden;
  position: relative;
}
.imageBlock {
  width: auto;
  height: 164px;
  max-width: 100%;
}
.closeIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
