// Custom width classes
.w-24 {
    width: 24px !important;
}
.w-48 {
    width: 48px !important;
}
.w60 {
    width: 60% !important;
}
.w65 {
    width: 65% !important;
}
.w70 {
    width: 70% !important;
}
.w75 {
    width: 75% !important;
}
.w80 {
    width: 80% !important;
}
.w85 {
    width: 85% !important;
}
.w90 {
    width: 90% !important;
}
.w95 {
    width: 95% !important;
}
