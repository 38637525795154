.videoMediaWrapper {
  max-width: 100%;
  display: flex;
  justify-content: center;
  background-color: black;
  
}
.videoMediaWrapper > video {
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: 360px;
}
.videoMediaWrapperView{
  margin-bottom: 8px;
}
