.postDetailsHeaderWrapper {
  height: 64px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: white;
  flex-grow: 1;
}

.postDetailsHeaderWrapper--backIconHolder{
  cursor: pointer;
}

.postDetailsHeaderWrapper--toolBarArea {
  margin-left: 10px;
  /* margin-top: -3px; */
}
.postDetailsHeaderWrapper--toolBarArea span {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500px;
  color: rgba(72, 79, 103, 1);
}
.postDetailsContentWrapper {
  margin-top: 24px;
}

.progressContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
