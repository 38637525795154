.postMediaAttachment--image {
  object-position: center;
  object-fit: cover;
  width: 100%;
  /* height: 196px; */
  height: auto;
  background-position: 50%;
  background-size: cover;
  color: #000;
}
.carousel .slider-wrapper.axis-horizontal .slider {
  align-items: center;
}
.postMediaAttachment--video {
  display: block;
  max-width: 100%;
  /* max-height: 400px; */
  width: auto;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}
.lmWrapper__feed__post__body--content {
  word-wrap: break-word;
}
.carousel {
  /* height: 300px; */
}
/* .slider-wrapper.axis-horizontal{
  height: 100%;
}
.slider-wrapper.axis-horizontal > ul {
  height: 100%;
}
.slider-wrapper.axis-horizontal > ul > li{
  height: 100%;
}
.slider-wrapper.axis-horizontal > ul > li > img{
  height: 100%;
  width: auto;
}
.slider-wrapper.axis-horizontal > ul > li > video{
  height: 100%;
  width: auto;
} */
.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}
