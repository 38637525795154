.commentWrapper {
  padding: 0px;
  padding-bottom: 16px;
  padding-top: 16px;
}
.commentWrapper a {
  text-decoration: none;
}
.commentWrapper--username {
  /* margin-bottom: 4px; */
}
.commentWrapper__commentContent--username {
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(72, 79, 103, 1);
  margin-bottom: 4px;
}
.commentWrapper--contentContainer {
  flex-grow: 1;
  margin-left: 8px;
}

.commentWrapper--commentContent {
  padding: 12px;

  border-radius: 16px;
  background-color: rgba(230, 235, 245, 0.5);
}
.commentWrapper--commentActions {
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.commentWrapper--commentActions .commentWrapper {
  margin: 0 0 0 40px;
  border: none;
}
.commentWrapper--commentActions .likes-count {
  color: rgba(15, 30, 61, 0.4);
  line-height: 16px;
  font-size: 12px;
  margin: 0 3px;
  line-height: 16px;
}
.commentWrapper--commentActions .replies {
  color: rgba(15, 30, 61, 0.4);
  line-height: 16px;
  font-size: 12px;
  margin: 0 2px;
  line-height: 16px;
}
.commentWrapper__commentContent--content {
  font-size: 14px;
  font-weight: 400;
  color: rgba(72, 79, 103, 1);
  line-height: 20px;
  word-wrap: break-word;
}
.replies {
  font-size: 16px;
}

.commentWrapper--upperLayer {
  display: flex;
}
.commentWrapper__upperLayer--contentBox {
  /* padding: 12px 16px; */
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
}
.commentWrapper__upperLayer--menuActionArea {
  display: flex;
  align-items: center;
}
.commentWrapper__upperLayer--menuActionArea span:hover {
  background-color: rgba(230, 235, 245, 1);
  border-radius: 50%;
}

.dotAfter:after {
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-right: 4px;
  margin-left: 4px;
  display: inline-block;
  background-color: rgba(72, 79, 103, 0.5);
  vertical-align: middle;
}
