// Custom margin classes
.mb1 {
    margin-bottom: 1rem !important;
}
.mt1 {
    margin-top: 1rem !important;
}
.mb-3-5 {
    margin-bottom: 3.5rem !important;
}
.mt-3-5 {
    margin-top: 3.5rem !important;
}
.my-3-5 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
}

.mb-4-5 {
    margin-bottom: 4.5rem !important;
}

.m5-5 {
    margin: 5.5rem !important;
}
.ml5-5 {
    margin-left: 5.5rem !important;
}
.mr5-5 {
    margin-right: 5.5rem !important;
}
.mt5-5 {
    margin-top: 5.5rem !important;
}
.mb5-5 {
    margin-bottom: 5.5rem !important;
}
.mx5-5 {
    margin-left: 5.5rem !important;
    margin-right: 5.5rem !important;
}
.my5-5 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
}

.m6 {
    margin: 6rem !important;
}
.ml6 {
    margin-left: 6rem !important;
}
.mr6 {
    margin-right: 6rem !important;
}
.mt6 {
    margin-top: 6rem !important;
}
.mb6 {
    margin-bottom: 6rem !important;
}
.mx6 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
}
.my6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
}

.m7 {
    margin: 9rem !important;
}
.ml7 {
    margin-left: 9rem !important;
}
.mr7 {
    margin-right: 9rem !important;
}
.mt7 {
    margin-top: 9rem !important;
}
.mb7 {
    margin-bottom: 9rem !important;
}
.mx7 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
}
.my7 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
}

.mt-8 {
    margin-top: 8px !important;
}
.mt-16 {
    margin-top: 16px !important;
}
.mt-32 {
    margin-top: 32px !important;
}
