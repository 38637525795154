* {
  font-family: Roboto;
}

.resourceViewheader {
  height: 76px;
  padding: 16px 16px 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userInformationWrapper {
  /* height: 52px; */
  display: flex;
  flex-grow: 1;
}

.userInformationWrapper--imgContainer {
  margin-right: 8px;
  height: 52px;
  width: 52px;
}

.userInformationWrapper--profileDetailsContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 460px;

}

.userInformationWrapper__profileDetailsContainer--mainTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin-right: 8px;
  /* width: 450px; */
  /* flex */
}

.userInformationWrapper__profileDetailsContainer__mainTitle--userTitle {
  padding: 2px 8px 2px 8px;
  border-radius: 2px;
  background: #00897b;
  width: 60%;
}

.menuItem {
  width: 196px;
  height: 56px;
  padding: 18px;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  line-height: 20px;
  cursor: pointer;
}

.resourceViewheader--view {
  padding-top: 0px;
  padding-bottom: 0px;
  height: auto;
}

.menuItemWrapper {
  cursor: pointer;
  /* display: flex; */
}

.post-details-header {
  border-radius: 8px;
}

.showEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userInformationWrapper__profileDetailsContainer--duration {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #666666;
}

.userInformationWrapper__profileDetailsContainer--subTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #666666;
}

.userInformationWrapper__profileDetailsContainer__mainTitle--username {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
}

.elevated-dot>.dot {
  /* border-radius: 50%;
  width: 2px;
  height: 2px;
  margin-bottom: 2px;
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
  background-color: #666666; */
  background-color: rgba(72, 79, 103, .5);
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 4px;
  margin-left: 4px;
  margin-right: 4px;
  vertical-align: middle;
  width: 4px;
  margin-bottom: 2px;
}

.elevated-dot {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #666666;
}