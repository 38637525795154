.p6 {
    padding: 6rem !important;
}
.pl6 {
    padding-left: 6rem !important;
}
.pr6 {
    padding-right: 6rem !important;
}
.pt6 {
    padding-top: 6rem !important;
}
.pb6 {
    padding-bottom: 6rem !important;
}
.px6 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
}
.py6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
}
