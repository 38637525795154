.inputFieldWrapper {
  width: 100%;
  /* padding-bottom: 24px; */
}
.heading {
  line-height: 24px;
  font-size: 16px;
  color: #333333;
  font-weight: 500;
}
.input {
  width: 100%;
  border: none;
  line-height: 24px;
  font-size: 16px;
  padding: 0;
  font-weight: 500;
  /* padding-top: 1px; */
  padding-top: 24px;
  padding-bottom: 24px;
  /* border-bottom: 1px solid #d0d8e2; */
  /* margin-bottom: 24px; */
}
.separator{
  
  border-bottom: 1px solid #d0d8e2;
  margin-top: 0px !important;

}
.input:focus-visible {
  outline: none;
}
.text-input::placeholder{
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
  color: black;
}
-webkit-input-placeholder::after{
  content: '*';
  color: red;
}
[placeholder] + span::after{
  content:"*";
  color: red;
}
input::placeholder:after{
  content: "*";
  font-size: 16px;
  width: 16px;
  height: 16px;
}
.link-text-input{
  color: #007AFF;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
}
.link-text-input::placeholder{
  line-height: 20px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 0.7);
  
}