.previewWrapper {
  height: 68px;
  width: 328px;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
  background-color: #e6ebf5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.docContent {
  display: flex;
  align-items: center;
  /* width: 100%; */
}
.docContent__metaDetails {
  margin-left: 8px;
  width: 180px;
}
.docContent__metaDetails > p{
  margin-bottom: 0px;
}
.docContent--metaDetails__name {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: Roboto;
  margin-bottom: 2px;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis;
  width: 100%;
}
.docContent--metaDetails__size {
  font-size: 11px;
  line-height: 14px;
  color: #9b9b9b;
}

.deleteIcon {
  cursor: pointer;
}
