.mediaWrapper {
  padding: 0px 16px 10px 16px;
}
.imageContainer {
  width: 544px;
  height: 309px;
  border-radius: 8px;
  /* border: 1px solid #E6EBF5; */
}
.imageContainer > img {
  width: 544px;
  height: 309px;
  border-radius: 8px;
  border: 1px solid #E6EBF5;
}
