.date-input-class {
    left: 0px;
    top: -48px;
    width: 512px;
    border-radius: 8px;

    .bs-datepicker-container {
        width: 100%;
        padding: 0;
        .bs-calendar-container .bs-media-container bs-days-calendar-view,
        .bs-calendar-container .bs-media-container bs-month-calendar-view,
        .bs-calendar-container .bs-media-container bs-years-calendar-view {
            width: 100%;
            .bs-datepicker-body {
                border: 0;
            }
            bs-calendar-layout {
                .bs-datepicker-head {
                    bs-datepicker-navigation-view {
                        button.previous {
                            color: #fff;
                            span::after {
                                content: url(../images/svg/left-arrow-grey.svg);
                                position: absolute;
                                left: 3px;
                                top: 4px;
                            }
                        }
                        button.next {
                            color: #fff;
                            span::after {
                                content: url(../images/svg/right-arrow-grey.svg);
                                position: absolute;
                                left: 3px;
                                top: 4px;
                            }
                        }
                        button:hover,
                        button:active {
                            background-color: none !important;
                        }
                        button.current {
                            font-size: 16px;
                            font-weight: bold;
                            text-align: center;
                            color: #2c3135;
                        }
                    }
                }
                .bs-datepicker-body table tbody tr td span.selected {
                    background-color: #50e3c2;
                    color: #fff;
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .date-input-class {
        left: -55px;
        width: 140%;
    }
}

@media screen and (max-width: 375px) {
    .date-input-class {
        left: -30px;
        width: 122%;
    }
}

@media screen and (max-width: 365px) {
    .date-input-class {
        left: -5px;
        width: 104%;
    }
}
