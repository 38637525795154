owl-carousel-o.managed-by-carousel-web {
    position: relative;
    .owl-carousel .owl-stage-outer {
        margin: 20px 96px;
    }
    .owl-carousel .owl-nav {
        position: absolute;
        padding-top: 8%;
        display: flex;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        top: 0;
        .owl-prev,
        .owl-next {
            background-color: transparent;
        }
        .owl-prev {
            margin-left: 20px;
        }
        .owl-next {
            margin-right: 20px;
        }
    }
    .owl-carousel .owl-dots {
        margin-top: 7%;
    }
}

owl-carousel-o.event-hosts {
    position: relative;
    .owl-carousel .owl-stage-outer {
        margin: 20px 0 0;
    }
    .owl-carousel .owl-nav {
        position: absolute;
        padding-top: 8%;
        display: flex;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        top: 0;
        .owl-prev,
        .owl-next {
            background-color: transparent;
            img {
                width: 48px;
            }
        }
        .owl-prev {
            margin-left: 20px;
        }
        .owl-next {
            margin-right: 20px;
        }
    }
    .owl-carousel .owl-dots {
        margin-top: -10px;
        margin-bottom: 25px;
        .owl-dot span {
            background-color: #9b9b9b !important;
        }
    }
    .owl-carousel .owl-dots {
        .owl-dot.active span {
            opacity: 0.4;
        }
    }
}

owl-carousel-o.event-banner-carousel {
    .owl-carousel {
        width: 100% !important;
        .owl-stage-outer owl-stage .owl-stage {
            .owl-item.cloned {
                opacity: 0;
            }
            .owl-item img {
                height: 340px;
                object-fit: cover;
            }
        }
    }
}

owl-carousel-o.media-carousel {
  .owl-carousel.owl-drag .owl-item {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }
}

owl-carousel-o.tutorial-carousel {
  .owl-carousel .owl-dots {
    .owl-dot {
      span {
        background: #ffffff;
        opacity: 0.5;
      }
      &.active span {
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 425px) {
    owl-carousel-o.managed-by-carousel-mob,
    owl-carousel-o.event-hosts,
    owl-carousel-o.media-carousel
    {
        .owl-carousel .owl-nav {
            display: none !important;
        }
        .owl-carousel .owl-dots {
            margin-bottom: 20px;
        }
    }
    owl-carousel-o.event-banner-carousel {
        .owl-carousel .owl-stage-outer owl-stage .owl-stage .owl-item img {
            height: 240px;
        }
    }
}
