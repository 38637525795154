* {
  font-family: Roboto;
}

.linkMediaWrapper {
  padding: 16px 16px 8px 16px;
  position: relative;
}

.ogPreview {
  height: 184px;
  width: auto;
  margin-left: 50%;
  margin-right: 50%;
  transform: translateX(-50%);
  padding: 16px 0px;
  max-width: 100%;
}

.ogDetails {
  padding: 16px;
  border-top: 1px solid #e6ebf5;
}

.ogDetails--title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
}

.ogDetails--subtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(72, 79, 103, 0.7);
}

.ogTagContent {
  border-radius: 8px;
  border: 1px solid #e6ebf5;
}

.closeIconOgTag {
  position: absolute;
  right: 0px;
  top: 0px;
}

.creation {
  display: flex;
  align-items: center;
  height: 56px;
  margin: 8px 0px;
  /* padding-bottom: 24px; */

}

.separator {
  margin-top: 24px;
  border-bottom: 1px solid #d0d8e2;
}

.creation--imgContainer {
  width: 78px;
  text-align: center;
  margin-right: 6px;
}

.creation--imgContainer>img {
  width: 100%;
  height: auto;
  max-width: 56px;
}

.creation--content {
  flex-grow: 1;
}

.deleteIcon {
  padding: 19px 0px 19px 19px;
  cursor: pointer;
}

.creation--content--title>p {
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  color: #333333;
  margin-bottom: 0px;
}

.creation--content--description>p {
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto;
  color: #666666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 600px;
  height: 20px;
  margin-bottom: 0px;
}

.creation--content--link>p {
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
  font-family: Roboto;
  color: #666666;
  margin-bottom: 0px;
}

.ogDetails--description {
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto;
  color: #666666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 500px;
  height: 20px;
}

.deleteIconEdit {
  /* padding: 19px 0px 19px 19px; */
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 16px
}

.broken-link-wrapper {
  width: 100%;
  height: 216px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E5F3F2;
}

.brokenLinkContent {
  text-align: center;
}

.urlText {
  margin-top: 4px;
  color: #00897B;
  margin-bottom: 0px !important;
}

.urlText:visited {
  color: #00897B;
}