/* Angular Material CSS changes*/
mat-form-field .mat-form-field-wrapper {
    padding-bottom: 0 !important;

    .mat-form-field-flex {
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
        border: solid 1px #d0d8e2;
        background-color: #ffffff;
        padding: 0 10px;
        line-height: 1.43;
        font-size: 14px;

        .mat-form-field-infix {
            padding: 0;
            min-height: 30px;
            border: 0;
            margin-top: 0;
            mat-select .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow {
                margin-top: 25px;
                margin-right: 16px;
            }
            .mat-form-field-label-wrapper .mat-form-field-label {
                margin-top: 7px;
                opacity: 0.3;
                color: #000000;
            }
        }
        .mat-form-field-suffix {
            mat-datepicker-toggle .mat-icon-button {
                margin-top: 6px;
            }
        }
        textarea {
            height: 100%;
        }
    }
    .mat-form-field-underline {
        display: none;
    }
}
mat-form-field.introduction .mat-form-field-wrapper .mat-form-field-flex textarea {
    height: calc(100% - 14px) !important;
}
mat-form-field.invalid {
    border: 1px solid red;
    border-radius: 8px;
}
mat-form-field.text-area .mat-form-field-wrapper .mat-form-field-flex {
    height: 84px;
}
mat-form-field.simple-select .mat-form-field-wrapper .mat-form-field-flex,
mat-form-field.simple-input .mat-form-field-wrapper .mat-form-field-flex,
mat-form-field.date-picker .mat-form-field-wrapper .mat-form-field-flex {
    min-height: 48px;
}
mat-progress-bar.small {
    width: 60%;
    margin: 17px 0;
    height: 6px;
    border-radius: 2px;

    .mat-progress-bar-fill::after {
        background-color: #06c3af;
    }

    .mat-progress-bar-buffer {
        background-color: #d8d8d8;
    }

    /* remove animation and the dots*/
    .mat-progress-bar-background {
        animation: none;
        background-color: #eceff1;
        fill: #eceff1;
    }
}

.media-dialog mat-dialog-container {
    padding: 8px;
}

// Snackbar
.snackbar {
    min-width: 100px !important;
    width: auto !important;
    margin: auto !important;
    margin-bottom: 50px !important;
    background-color: #191919 !important;
    color: #fff !important;
    border-radius: 8px !important;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.24), 0 0 6px 0 rgba(0, 0, 0, 0.12) !important;
    font-weight: 500 !important;
}

.mat-bottom-sheet-container {
    border-radius: 10px 10px 0 0;
    &.country-list {
        min-height: 80vh;
    }
}

.mat-form-field-invalid .mat-input-element {
    caret-color: auto;
}
.invite-member {
    mat-form-field {
        width: 100%;
        margin-bottom: 5px;
        .mat-form-field-wrapper .mat-form-field-flex {
            box-shadow: none;
            overflow-y: auto;
            max-height: 100px;
            padding-top: 5px;
        }
    }
}

.mat-field-error {
    mat-form-field {
        .mat-form-field-wrapper .mat-form-field-flex {
            border-color: #ff3b30;
        }
    }
}
// Generate OTP mobile input CSS
.generate-otp-mobile-input {
    mat-form-field.country-list {
        position: absolute;
        z-index: 1;
        width: 100px;
        opacity: 0;
        .mat-form-field-wrapper .mat-form-field-flex {
            padding: 4px 0 4px 8px;
            box-shadow: none;
            border-radius: 8px 0 0 8px;
            .mat-form-field-infix mat-select .mat-select-trigger {
                .mat-select-arrow-wrapper .mat-select-arrow {
                    margin: 25px 10px 0 0;
                }
            }
        }
    }
    mat-form-field.simple-input {
        .mat-form-field-wrapper .mat-form-field-flex {
            box-shadow: none;
            .mat-form-field-infix {
                margin-left: 100px;
            }
        }
    }
    mat-form-field.invalid-border .mat-input-element {
        caret-color: #f44336;
    }
}

// Login Screen fields
mat-form-field.login-name {
    // margin-left: 15px;
    .mat-form-field-wrapper .mat-form-field-flex {
        .mat-form-field-infix .mat-input-element {
            margin-top: 13px !important;
        }
        .mat-form-field-prefix {
            margin: auto;
            margin-right: 10px;
        }
    }
}
mat-form-field.login-name.invalid-border {
    border: 1px solid #d0021b;
    border-radius: 8px;
    .mat-form-field-wrapper .mat-form-field-flex {
        .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
            color: #d0021b;
            opacity: 1;
        }
    }
}
mat-form-field.login-email {
    .mat-form-field-wrapper .mat-form-field-flex {
        .mat-form-field-infix .mat-input-element, mat-select {
            margin-top: 13px !important;
        }
        .mat-form-field-prefix {
            margin: auto;
            margin-right: 10px;
        }
    }
}
mat-form-field.login-email.invalid-border {
    border: 1px solid #d0021b;
    border-radius: 8px;
    .mat-form-field-wrapper .mat-form-field-flex {
        .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
            color: #d0021b;
            opacity: 1;
        }
    }
}

// Question form field CSS
mat-form-field.simple-select-without-search {
    .mat-form-field-wrapper .mat-form-field-flex {
        height: 48px;
        .mat-form-field-infix mat-select .mat-select-trigger {
            .mat-select-value {
                padding: 13px 0;
            }
            .mat-select-arrow-wrapper .mat-select-arrow {
                margin-top: 48px;
                margin-right: 10px;
            }
        }
        .mat-form-field-infix mat-select.mat-select-invalid .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow {
            color: rgba(0, 0, 0, 0.54);
        }
    }
}
mat-form-field.simple-select-with-search {
    .mat-form-field-wrapper .mat-form-field-flex {
        .mat-form-field-infix .mat-input-element {
            margin-top: 14px;
        }
    }
}
mat-form-field.multi-select-with-search {
    .mat-form-field-wrapper .mat-form-field-flex {
        .mat-form-field-infix {
            .mat-chip-list-wrapper {
                margin: 4px -4px;
                mat-chip {
                    height: 30px;
                    border-radius: 15px;
                    border: solid 1px #06c3af;
                    background-color: rgba(6, 195, 175, 0.1);
                    color: #06c3af;
                    .mat-chip-remove {
                        opacity: 1;
                        margin: 0 0 3px 0;
                    }
                }
            }
            .mat-select-arrow-wrapper .mat-select-arrow {
                margin-top: 6px;
                margin-right: 10px;
            }
        }
        .mat-form-field-infix input {
            margin-top: 13px !important;
        }
    }
}
mat-form-field.multi-select-without-search {
    .mat-form-field-wrapper .mat-form-field-flex {
        .mat-form-field-infix mat-select .mat-select-trigger {
            .mat-select-value {
                padding: 13px 0;
                .mat-select-value-text mat-select-trigger mat-chip-list .mat-chip-list-wrapper mat-chip {
                    height: 30px;
                    border-radius: 15px;
                    border: solid 1px #06c3af;
                    background-color: rgba(6, 195, 175, 0.1);
                    color: #06c3af;
                    .mat-chip-remove {
                        opacity: 1;
                        margin: 0 0 3px 0;
                    }
                }
            }
            .mat-select-arrow-wrapper .mat-select-arrow {
                margin-top: 6px;
                margin-right: 10px;
            }
        }
        .mat-form-field-infix mat-select.mat-select-invalid .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow {
            color: rgba(0, 0, 0, 0.54);
        }
    }
}
mat-form-field.file-upload {
    .mat-form-field-wrapper .mat-form-field-flex {
        min-height: 52px;
        .mat-form-field-infix input {
            position: absolute;
            height: 48px;
            cursor: pointer;
        }
        .mat-form-field-suffix {
            right: 10px;
            position: absolute;
            top: 15px;
            // margin-right: 4px;
        }
    }
}
mat-form-field.profile-link,
mat-form-field.short-answer,
mat-form-field.date-and-time,
mat-form-field.phone-number,
mat-form-field.email,
mat-form-field.google-search {
    .mat-form-field-wrapper .mat-form-field-flex {
        .mat-form-field-infix input {
            margin-top: 13px;
        }
    }
}

mat-form-field.google-search {
    .mat-form-field-wrapper .mat-form-field-flex {
        .mat-form-field-infix input {
            margin-top: 14px !important;
        }
    }
}
mat-form-field.date-and-time {
    .mat-form-field-wrapper .mat-form-field-flex {
        .mat-form-field-infix input {
            margin: 0;
            height: 48px;
        }
        .mat-form-field-suffix {
            position: absolute;
            right: 10px;
            top: 18px;
            img {
                height: 22px;
                margin-top: -8px;
                opacity: 0.4;
            }
        }
    }
}
mat-form-field.introduction,
mat-form-field.paragraph {
    .mat-form-field-wrapper .mat-form-field-flex {
        .mat-form-field-infix {
            height: 100%;
            textarea {
                padding-top: 14px;
            }
        }
    }
}

mat-form-field.simple-input.chatroom-input .mat-form-field-wrapper .mat-form-field-flex {
    border-radius: 24px !important;
    max-height: 48px;
    .mat-form-field-prefix img {
        cursor: pointer;
        margin-top: -45px;
    }
    .mat-form-field-infix {
        input,
        textarea {
            margin-top: 13px;
        }
        .mat-form-field-label-wrapper {
            width: 90%;
            margin-left: 10px;
        }
    }
    .mat-form-field-suffix img {
        height: 24px;
        opacity: 0.4;
        position: absolute;
        right: 0px;
        top: -38px;
        cursor: pointer;
    }
}

// Generate OTP mobile field overlay CSS
.cdk-overlay-container {
    z-index: 1082;
    .cdk-overlay-pane .country-list-panel {
        min-width: calc(100% + 85px) !important;
        margin-left: 7px;
        margin-top: -6px;

        mat-form-field.simple-input .mat-form-field-wrapper .mat-form-field-flex {
            margin: 15px 0px 0 15px;
            width: 93%;
            box-shadow: none;
            border: solid 1px #d0d8e2;
        }
    }
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #009688;
    .mat-option-text {
        color: #000000 !important;
        font-size: 15px !important;
    }
}

// Modal CSS
.hint-modal {
    position: relative !important;
    // max-width: 400px !important;
}

.download-app-modal,
.merge-account-modal,
.verify-otp-modal,
.leave-page-modal {
    border-radius: 2px;
    box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.3), 0 0 24px 0 rgba(0, 0, 0, 0.22);
    mat-dialog-container {
        padding: 12px !important;
    }
}

.send-response-modal {
    mat-dialog-container {
        // padding: 12px !important;
        border-radius: 16px;
        position: relative;
        padding: 24px 12px;
    }
}

.razorpay-inbuild-modal {
    mat-dialog-container {
        padding: 0;
    }
}

.invite-via-email-mobile {
    max-height: 100vh !important;
    padding: 0px !important;
}

.blurr-backdrop {
    backdrop-filter: blur(3px) !important;
    border: 1px solid green;
}

.download-app-modal {
    width: 400px !important;
    max-height: 400px;
}

.available-only-on-app {
    max-width: 460px !important;
    mat-dialog-container {
        border-radius: 16px !important;
    }
}

.overflow-modal {
    overflow: inherit !important;
}

.send-response-modal {
    width: 542px !important;
    max-height: 550px;
}

.attend-event-modal {
    width: 410px !important;
    max-height: 550px;
}

.merge-account-modal {
    width: 380px;
    min-height: 355px;
}

.verify-otp-modal {
    width: 380px;
    min-height: 426px;
}

.leave-page-modal {
    width: 380px;
    min-height: 260px;
}

.update-profile-popup {
    width: 375px;
    .mat-dialog-container {
        padding: 15px;
    }
}

.event-join-community-popup {
    width: 440px;
}

.zoom-meeting-popup {
    height: 306px;
    width: 460px;
    .mat-dialog-container {
        background: #ffffff;
        box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
        border-radius: 10px;
        padding: 22.63px 20px 16px;
    }
}

.google-meet-popup {
    height: 210px;
    width: 460px;
    .mat-dialog-container {
        background: #ffffff;
        box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
        border-radius: 16px;
        padding: 22.63px 20px 16px;
    }
}

.offline-meet-popup {
    height: 419px;
    width: 460px;
    .mat-dialog-container {
        background: #ffffff;
        box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
        border-radius: 16px;
        padding: 22.63px 20px 16px;
    }
}

.create-event-duration-popup {
    height: 246px;
    width: 280px;
    .mat-dialog-container {
        background: #ffffff;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.22), 0px 24px 24px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        padding: 24px;
    }
}

.free-event-access-popup {
    width: 346px;
    height: 220px;
    .mat-dialog-container {
        background: #ffffff;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.22), 0px 24px 24px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        padding: 24px;
    }
}

.create-event-add-content-popup {
    width: 360px;
    min-height: 194px;

    .mat-dialog-container {
        background: #ffffff;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.22), 0px 24px 24px rgba(0, 0, 0, 0.3);
        border-radius: 16px;
        padding: 24px;
    }
}

.leave-community-bg {
    background: #ffffff;
}

.vote-submit-popup {
    width: 450px;
    min-height: 400px;
    text-align: center;
    justify-content: center;
    padding: 70px 40px;
}

.add-poll-option-popup {
    width: 450px;
    min-height: 300px;
    text-align: center;
    justify-content: center;
    padding: 70px 40px;
}

.anonymous-poll-popup {
    width: 430px;
    min-height: 200px;
    text-align: center;
    justify-content: center;
    padding: 70px 40px;
}

.notVisibleBackdrop {
    background: rgba(96, 96, 96, 1);
}

mat-progress-spinner.introduction-progress-spinner {
    width: 25px !important;
    height: 25px !important;
    position: absolute;
    right: 20px;
    svg {
        width: 25px !important;
        height: 25px !important;
        circle {
            stroke: #06c3af;
        }
    }
}

.progress-tooltip {
    font-weight: 500;
    font-size: 13px;
    position: absolute;
    width: 165px;
    margin-top: -30px !important;
    margin-left: -126px !important;
    &.success {
        background-color: #06c3af;
    }
    &.error {
        background-color: #d0021b;
    }
}

/* Mat menu panel */
.cdk-overlay-pane {
    .mat-menu-panel.upload-menu {
        margin-bottom: 28px;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16), 0 2px 16px 0 rgba(0, 0, 0, 0.24);
        max-width: none;
    }
    .mat-menu-panel.upload-menu::after {
        content: '';
        width: 0;
        height: 0;
        display: block;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #ffffff;
        position: absolute;
        left: 15px;
    }
    .mat-menu-panel.left-panel__card::after {
        content: '';
        width: 0;
        height: 0;
        display: block;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #ffffff;
        position: absolute;
        left: 71px;
        transform: rotate(90deg);
        bottom: -23px;
    }

    .mat-menu-panel.left-panel-options::after {
        content: '';
        width: 0;
        height: 0;
        display: block;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #ffffff;
        position: absolute;
        left: 39px;
        transform: rotate(90deg);
        bottom: 353px;
    }

    .mat-menu-panel.mat-message-action {
        min-height: fit-content;
    }
}

//CM Dashboard Location Input
.location {
    .mat-input-element {
        border: none !important;
    }
}

/* Media Query */

@media screen and (max-width: 1440px) {
    .cdk-overlay-container .cdk-overlay-pane .country-list-panel {
        min-width: calc(100% + 93px) !important;
        max-height: 261px;
    }
}
@media screen and (max-width: 1025px) {
    .cdk-overlay-container .cdk-overlay-pane .country-list-panel {
        min-width: calc(100% + 80px) !important;
    }
}

@media screen and (max-width: 768px) {
    .cdk-overlay-container .cdk-overlay-pane .country-list-panel {
        min-width: calc(100% + 93px) !important;
    }
    .progress-tooltip {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }
}

@media screen and (max-width: 425px) {
    .cdk-overlay-container .cdk-overlay-pane .country-list-panel {
        min-width: calc(100% + 118px) !important;
    }

    .download-app-modal {
        max-width: 320px !important;
        mat-dialog-container {
            padding: 20px !important;
        }
    }

    .available-only-on-app {
        max-width: 380px !important;
        mat-dialog-container {
            padding: 20px !important;
            border-radius: 16px !important;
        }
    }

    .mat-select-panel.multi-select-without-search {
        min-width: calc(100% + 20px) !important;
        margin-left: 9px !important;
    }

    .mat-select-panel.simple-select-without-search {
        min-width: calc(100% + 20px) !important;
        margin-left: 6px !important;
        margin-top: -5px !important;
    }

    .cdk-overlay-pane {
        .mat-menu-panel.upload-menu {
            margin-bottom: 22px;
        }
        .mat-menu-panel.upload-menu::after {
            display: none;
        }
    }
}

@media screen and (max-width: 375px) {
    .cdk-overlay-container .cdk-overlay-pane .country-list-panel {
        min-width: calc(100% + 93px) !important;
    }

    .download-app-modal {
        max-width: 290px !important;
    }

    .available-only-on-app {
        max-width: 350px !important;
        mat-dialog-container {
            border-radius: 16px !important;
        }
    }
}

//For CM Dashboard

.snackbar-toast {
    position: fixed;
    right: 2px !important;
    top: 2px !important;
    .mat-button-base {
        display: flex;
        align-items: center;
        color: #3366ff !important;
        background: white;
        border-radius: 24px;
        padding: 0px 8px;
        max-height: 28px !important;
    }
}

.bg-primary-dashboard {
    background: #3366ff !important;
}

.modal-dialog {
    display: flex;
    height: 100vh;
    align-items: center;
    overflow-y: scroll;
}

.modal-content {
    max-height: 90vh;
    overflow-y: scroll;
    border: 0;
}

.angular-editor-wrapper {
    background: rgba(143, 155, 179, 0.08) !important;
}

.cm-dashboard {
    .mat-date-range-input-container {
        transform: translateY(-9px) !important;
    }

    .mat-form-field-flex {
        border-radius: 4px !important;
        height: 40px !important;
        box-shadow: none !important;
    }
}

.rpay-wrapper {
    .mat-form-field-invalid .mat-form-field-ripple {
        background-color: #f44336 !important;
    }
    .mat-form-field {
        font-size: inherit !important;
        font-weight: 400 !important;
        line-height: 1.125 !important;
        font-family: Roboto, 'Helvetica Neue', sans-serif !important;
        letter-spacing: normal !important;
        margin-top: 20px !important;
        display: block !important;
        .mat-form-field-wrapper {
            position: relative !important;
            padding-bottom: 1.25em !important;
            .mat-form-field-flex {
                border: none;
                border-radius: 4px !important;
                height: 40px !important;
                box-shadow: none !important;
                display: inline-flex !important;
                align-items: baseline !important;
                box-sizing: border-box !important;
                width: 100% !important;
                .mat-form-field-infix {
                    display: block !important;
                    position: relative !important;
                    flex: auto !important;
                    min-width: 0 !important;
                    width: 30px !important;
                    padding: 0.5em 0 !important;
                    .mat-select-arrow-wrapper {
                        .mat-select-arrow {
                            margin-top: 0 !important;
                            margin-right: 0 !important;
                        }
                    }
                    .mat-form-field-label-wrapper {
                        .mat-form-field-label {
                            margin-top: 0;
                            opacity: 0.6;
                            // color: #757575;
                            position: absolute !important;
                            left: 0 !important;
                            font: inherit !important;
                            pointer-events: none !important;
                            width: 100% !important;
                            white-space: nowrap !important;
                            text-overflow: ellipsis !important;
                            overflow: hidden !important;
                            transform-origin: 0 0 !important;
                            transition: transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1), color 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
                                width 400ms cubic-bezier(0.25, 0.8, 0.25, 1) !important;
                        }
                    }
                }
            }

            .mat-form-field-underline {
                display: block !important;
                position: absolute !important;
                width: 100% !important;
                pointer-events: none !important;
                transform: scale3d(1, 1.0001, 1) !important;
                .mat-form-field-ripple {
                    background-color: #979797 !important;
                    height: 1px !important;
                }
            }
        }
    }
}
