// Custom font size classes
.fs8 {
    font-size: 8px !important;
}
.fs10 {
    font-size: 10px !important;
}
.fs12 {
    font-size: 12px !important;
}
.fs13 {
    font-size: 13px !important;
}
.fs14 {
    font-size: 14px !important;
}
.fs15 {
  font-size: 15px !important;
}
.fs16 {
    font-size: 16px !important;
}
.fs18 {
    font-size: 18px !important;
}
.fs20 {
    font-size: 20px !important;
}
.fs22 {
    font-size: 22px !important;
}
.fs24 {
    font-size: 24px !important;
}
.fs26 {
    font-size: 26px !important;
}
.fs28 {
    font-size: 28px !important;
}
