.changeAuthorWrapper {
    font-family: Roboto;
}

.dialogHeader {
    height: 80px;
    position: relative;
    padding: 20px 0px 36px 0px;
}

.heading {
    line-height: 20px;
    font-size: 20px;
    font-weight: 500;
    margin: 0px;
    text-align: center;
    color: #484F67;
}

.dialogContent {
    padding: 0px 36px 0px 36px;
}

.dialogContentHeading {
    line-height: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    text-align: center;
    margin: 0px;
}

.displayWrapperAuthor {
    border-radius: 8px;
    padding: 0px 8px 0px 0px;
    /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.19); */
    height: 48px;
    display: flex;
    align-items: center;
}

.currentAuthorDisplayImage {
    height: 36px;
    width: 36px;
}

.currentAuthorDisplayImage>img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.currentAuthorNameContainer {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0px 12px;

}


.currentAuthorName {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #333333;
    cursor: pointer
}

.authorSelectContainer {
    /* padding-top: 24px;
    padding-bottom: 24px; */
    height: 48px;
}

.searchBoxContainer {
    padding: 6px 8px 0px 8px;
    position: relative;
}

.searchBoxInput {
    border-radius: 8px;
    height: 36px;
    padding: 6px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #333333;
    padding-left: 44px;
    background-color: rgba(208, 216, 226, 0.4);
    outline: none;
    border: none;
    width: 294px;
}

.searchBoxInput:active {
    outline: none !important;
    border: none !important;
}

.searchBoxInput:focus {
    outline: none !important;
    border: none !important;
}

.searchBoxIcon {
    position: absolute;
    top: 12px;
    left: 24px;
}

.authorTileContainer {
    padding: 0px 8px;
    margin: 16px 0px;
    display: flex;
    align-items: center;
    /* width: 232px; */
}

/* .authorTileContainer:hover {
    background-color: rgba(230, 235, 245, 1);
} */

.closeDialogIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1;
}

.submitAuthor {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    background-color: #00897B;
    color: #FFFFFF;
    border-radius: 8px;
    border: none;
    width: 100%;
    height: 48px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}

.button {
    display: inline-flex;
    width: 100%;
    padding: 0 36px 36px 36px;

}

.showConfirmMessageBox {
    padding: 0px 36px;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #666666;
    margin-bottom: 24px;
}